$(document).foundation();

$(document).ready(function() {
  $("#myTags").tagit();
});

//MAKE URL ACTIVE
$(function() {
  if ((location.pathname.split("/")[1]) !== ""){
    $('nav a[href^="/' + location.pathname.split("/")[1] + '"]').addClass('active');
  }
});

//SCROLL TO ANCHOR
// Select all links with hashes
$('a[href*="#"]')
// Remove links that don't actually link to anything
.not('[href="#"]')
.not('[href="#0"]')
.click(function(event) {
  // On-page links
  if (
    location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '')
      &&
      location.hostname == this.hostname
    ) {
      // Figure out element to scroll to
      var target = $(this.hash);
      target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
      // Does a scroll target exist?
      if (target.length) {
        // Only prevent default if animation is actually gonna happen
        event.preventDefault();
        $('html, body').animate({
          scrollTop: target.offset().top
        }, 2500, function() {
          // Callback after animation
          // Must change focus!
          var $target = $(target);
          $target.focus();
          if ($target.is(":focus")) { // Checking if the target was focused
            return false;
          } else {
            $target.attr('tabindex','-1'); // Adding tabindex for elements not focusable
            $target.focus(); // Set focus again
          };
        });
      }
    }
  });


  //Counter by Geoff Kimball <3
  // Element to apply the number to (swap out the '[data-count]' with whatever HTML you're using)
  var countElement = document.querySelector('[data-count]');

  // Starting point for the timer (the farther back in time, the bigger the starting number)
  var start = (new Date(2017, 8, 1)).getTime();
  // Frequency with which the timer is updated (in milliseconds)
  var timeInterval = 650;
  // Number to increment by each interval
  var wordInterval = 1;

  function calculate() {
    // Calculate the amount of time that's passed since the starting point
    var timeOffset = Date.now() - start;

    // Calculate what the new number should be
    var count = Math.round((timeOffset / timeInterval) * wordInterval);

    // Add commas to the number and then insert it into the HTML
    countElement.textContent = count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  // Run this function forever
  setInterval(calculate, timeInterval);

  // Run it once on page load
  calculate();

  //Statistics Counter
  // $('.counter').each(function() {
  //   var $this = $(this),
  //   countTo = $this.attr('data-count');
  //   $({ countNum: $this.text()}).animate({
  //     countNum: countTo
  //   },
  //   {
  //     duration: 1000,
  //     easing:'linear',
  //     step: function() {
  //       $this.text(Math.floor(this.countNum));
  //     },
  //     complete: function() {
  //       $this.text(this.countNum);
  //       //alert('finished');
  //       // calculate();
  //     }
  //   });
  // });

  (function($) {
    $.fn.countTo = function(options) {
      options = options || {};

      return $(this).each(function() {
        // set options for current element
        var settings = $.extend(
          {},
          $.fn.countTo.defaults,
          {
            from: $(this).data("from"),
            to: $(this).data("to"),
            speed: $(this).data("speed"),
            refreshInterval: $(this).data("refresh-interval"),
            decimals: $(this).data("decimals")
          },
          options
        );

        // how many times to update the value, and how much to increment the value on each update
        var loops = Math.ceil(settings.speed / settings.refreshInterval),
          increment = (settings.to - settings.from) / loops;

        // references & variables that will change with each update
        var self = this,
          $self = $(this),
          loopCount = 0,
          value = settings.from,
          data = $self.data("countTo") || {};

        $self.data("countTo", data);

        // if an existing interval can be found, clear it first
        if (data.interval) {
          clearInterval(data.interval);
        }
        data.interval = setInterval(updateTimer, settings.refreshInterval);

        // initialize the element with the starting value
        render(value);

        function updateTimer() {
          value += increment;
          loopCount++;

          render(value);

          if (typeof settings.onUpdate == "function") {
            settings.onUpdate.call(self, value);
          }

          if (loopCount >= loops) {
            // remove the interval
            $self.removeData("countTo");
            clearInterval(data.interval);
            value = settings.to;

            if (typeof settings.onComplete == "function") {
              settings.onComplete.call(self, value);
            }
          }
        }

        function render(value) {
          var formattedValue = settings.formatter.call(self, value, settings);
          $self.html(formattedValue);
        }
      });
    };

    $.fn.countTo.defaults = {
      from: 0, // the number the element should start at
      to: 0, // the number the element should end at
      speed: 1000, // how long it should take to count between the target numbers
      refreshInterval: 100, // how often the element should be updated
      decimals: 0, // the number of decimal places to show
      formatter: formatter, // handler for formatting the value before rendering
      onUpdate: null, // callback method for every time the element is updated
      onComplete: null // callback method for when the element finishes updating
    };

    function formatter(value, settings) {
      return value.toFixed(settings.decimals);
    }
  })(jQuery);

  jQuery(function($) {
    // custom formatting example
    $(".count-number").data("countToOptions", {
      formatter: function(value, options) {
        return value
          .toFixed(options.decimals)
          .replace(/\B(?=(?:\d{3})+(?!\d))/g, ",");
      }
    });

    // start all the timers
    $(".timer").each(count);

    function count(options) {
      var $this = $(this);
      options = $.extend({}, options || {}, $this.data("countToOptions") || {});
      $this.countTo(options);
    }
  });
